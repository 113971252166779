div[id^='wizard-id-'].wizard {
    display: inherit;
    justify-content: inherit;
    flex-flow: inherit;
    align-items: inherit;
    align-content: inherit;
    gap: inherit;
    width: 100%
}

.WizardFieldRestrictEditing, .WizardFieldRestrictEditing .EncryptedInput {
    pointer-events: none;
}

/* We need to enable the events in the encrypted fields with permissions to unmask, to be able to unmask them when they are read only */
.WizardFieldRestrictEditing[data-readonly-unmaskable="true"] {
    pointer-events: auto;
}

/* This enables horizontal scrollbar for disabled textarea and TinyMCE edition box (tox-edit-area__iframe) */
.WizardFieldRestrictEditing textarea, .fieldSpec--readonly .tox-edit-area__iframe {
    pointer-events: auto;
}

.WizardFieldRestrictEditing input,
.WizardFieldRestrictEditing textarea,
.WizardFieldRestrictEditing select,
.WizardFieldRestrictEditing button,
.WizardFieldRestrictEditing a {
    opacity: 0.4;
}

@media (forced-colors: active) {
    [aria-disabled="true"] {
        border-color: GrayText;
        color: GrayText;
    }
}

.WizardFieldHidden {
    display: none !important;
}
