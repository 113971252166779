.ControlsItem.Timer {
    color: var(--video--color--white);
    font-weight: var(--video--font--weight--bold);
    font-size: var(--video--spacer--16);
    line-height: normal;
    min-width: var(--video--spacer--60);
}

.ControlsItem.Timer:focus {
    color: var(--video--color--white);
    font-weight: var(--video--font--weight--bold);
    font-size: var(--video--spacer--16);
    line-height: normal;
    border-radius: var(--video--spacer--4);
    border: var(--video--border--black);
    outline: var(--video--border--white);
}

@media (width <= 425px) {
    .IsRecording .ControlsItem.Timer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--video--color--background);
        backdrop-filter: blur(var(--video--spacer--25));
        border-radius: var(--video--spacer--4);
        padding: var(--video--spacer--12) 0 var(--video--spacer--12) 0;
    }
}
