/** @FileTooLarge(323)*/
/*! jQuery UI - v1.10.4 - 2014-05-27
* http://jqueryui.com
* Includes: jquery.ui.core.css, jquery.ui.datepicker.css
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */

.ui-base-css .ui-helper-hidden {
    display: none;
}

.ui-base-css .ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.ui-base-css .ui-helper-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}

.ui-base-css .ui-helper-clearfix:before,
.ui-base-css .ui-helper-clearfix:after {
    content: "";
    display: table;
    border-collapse: collapse;
}

.ui-base-css .ui-helper-clearfix:after {
    clear: both;
}

.ui-base-css .ui-helper-clearfix {
    min-height: 0;
}

.ui-base-css .ui-helper-zfix {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    filter: Alpha(Opacity=0);
}

.ui-base-css .ui-front {
    z-index: 100;
}

.ui-base-css .ui-state-disabled {
    cursor: default !important;
}

.ui-base-css .ui-icon {
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
}

.ui-base-css .ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ui-base-css .ui-datepicker {
    width: 17em;
    background-color: #fff;
    border-style: solid;
    border-width: 1px;
    border-color: #BBBBBB;
    padding: 2px;
    display: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.ui-base-css .ui-datepicker .ui-datepicker-header {
    position: relative;
    margin: 0 0 2px 0;
    padding: 0;
    height: 2em;
}

.ui-base-css .ui-datepicker .ui-datepicker-prev,
.ui-base-css .ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 0;
    width: 2em;
    height: 2em;
    cursor: pointer;
}

.ui-base-css .ui-datepicker .ui-datepicker-prev-hover,
.ui-base-css .ui-datepicker .ui-datepicker-next-hover {
    top: 0;
    cursor: pointer;
}

.ui-base-css .ui-datepicker .ui-datepicker-prev {
    left: 0;
    background-color: #F0F0F0;
    background-image: url('default_datePrevious.png');
    background-position: center center;
    background-repeat: no-repeat;
}

.ui-base-css .ui-datepicker .ui-datepicker-next {
    right: 0;
    background-color: #F0F0F0;
    background-image: url('default_dateNext.png');
    background-position: center center;
    background-repeat: no-repeat;
}

.ui-base-css .ui-datepicker .ui-datepicker-prev-hover {
    left: 0;
}

.ui-base-css .ui-datepicker .ui-datepicker-next-hover {
    right: 0;
}

.ui-base-css .ui-datepicker .ui-datepicker-prev span,
.ui-base-css .ui-datepicker .ui-datepicker-next span {
    display: none;
    position: absolute;
    left: 50%;
    top: 0;
}

.ui-base-css .ui-datepicker .ui-datepicker-title {
    margin: 0;
    height: 2em;
    line-height: 2em;
    text-align: center;
}

.ui-base-css .ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
}

.ui-base-css .ui-datepicker select.ui-datepicker-month,
.ui-base-css .ui-datepicker select.ui-datepicker-year {
    width: 29%;
    height: 100%;
}

.ui-base-css .ui-datepicker table {
    width: 100%;
    font-size: 0.9em;
    border-collapse: collapse;
    margin: 0;
}

.ui-base-css .ui-datepicker th,
.ui-base-css .ui-datepicker td {
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: #DDDDDD #EEEEEE;
}

.ui-base-css .ui-datepicker th {
    font-weight: bold;
    background-color: #F0F0F0;
    color: #606060;
}

.ui-base-css .ui-datepicker-unselectable.ui-state-disabled,
.ui-base-css .ui-datepicker-unselectable.ui-state-disabled span,
.ui-base-css .ui-datepicker-other-month.ui-datepicker-unselectable {
    background-color: #F4F4F4;
    color: #808080;
}

.ui-base-css .ui-datepicker th span,
.ui-base-css .ui-datepicker th a,
.ui-base-css .ui-datepicker td span,
.ui-base-css .ui-datepicker td a {
    display: block;
    padding: 0.35em 0.25em;
    text-align: center;
    text-decoration: none;
}

.ui-base-css .ui-datepicker td span,
.ui-base-css .ui-datepicker td a {
    color: #404040;
}

.ui-base-css .ui-datepicker td span.ui-state-hover,
.ui-base-css .ui-datepicker td a.ui-state-hover {
    background-color: #E7EEFD;
    color: #000000;
}

.ui-base-css .ui-datepicker .ui-datepicker-current-day,
.ui-base-css .ui-datepicker .ui-datepicker-current-day a,
.ui-base-css .ui-datepicker .ui-datepicker-current-day a.ui-state-hover {
    background-color: #DAE7FD;
    color: #000000;
}

.ui-base-css .ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    margin: .7em 0 0 0;
    padding: 0 .2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

.ui-base-css .ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
    margin: .5em .2em .4em;
    cursor: pointer;
    padding: .2em .6em .3em .6em;
    width: auto;
    overflow: visible;
}

.ui-base-css .ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
}

.ui-base-css .ui-datepicker.ui-datepicker-multi {
    width: auto;
}

.ui-base-css .ui-datepicker-multi .ui-datepicker-group {
    float: left;
}

.ui-base-css .ui-datepicker-multi .ui-datepicker-group table {
    width: 95%;
    margin: 0 auto .4em;
}

.ui-base-css .ui-datepicker-multi-2 .ui-datepicker-group {
    width: 50%;
}

.ui-base-css .ui-datepicker-multi-3 .ui-datepicker-group {
    width: 33.3%;
}

.ui-base-css .ui-datepicker-multi-4 .ui-datepicker-group {
    width: 25%;
}

.ui-base-css .ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-base-css .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 0
}

.ui-base-css .ui-datepicker-multi .ui-datepicker-buttonpane {
    clear: left;
}

.ui-base-css .ui-datepicker-row-break {
    clear: both;
    width: 100%;
    font-size: 0;
}

.ui-base-css .ui-datepicker-rtl {
    direction: rtl;
}

.ui-base-css .ui-datepicker-rtl .ui-datepicker-prev {
    right: 2px;
    left: auto;
}

.ui-base-css .ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto;
}

.ui-base-css .ui-datepicker-rtl .ui-datepicker-prev:hover {
    right: 1px;
    left: auto;
}

.ui-base-css .ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
}

.ui-base-css .ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear: right;
}

.ui-base-css .ui-datepicker-rtl .ui-datepicker-buttonpane button {
    float: left;
}

.ui-base-css .ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-base-css .ui-datepicker-rtl .ui-datepicker-group {
    float: right;
}

.ui-base-css .ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-base-css .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
}
