.Modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: hsl(0 0% 0% / .4);
    height: 100%;
    inline-size: 100%;
    z-index: 100;
}

.Modal .SettingsModal {
    display: flex;
    flex-direction: column;
    position: absolute;
    min-height: 150px;
    max-width: 400px;
    min-width: 400px;
    border-radius: var(--video--spacer--8);
    background: #2D2D2D;
    backdrop-filter: blur(200px);
    padding: var(--video--spacer--16) var(--video--spacer--28) 32px var(--video--spacer--28);
    gap: var(--video--spacer--16);
}

.SettingsModal .SettingsModalTitleContainer {
    display: flex;
}

.SettingsModalTitle {
    font-family: var(--video--font--family);
    font-size: 26px;
    font-weight: var(--video--font--weight--bold);
    color: var(--video--color--white);
    width: 370px;
}

@media (width <= 425px) {
    .Modal .SettingsModal {
        max-width: 250px;
        min-width: 250px;
    }
}
