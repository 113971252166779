/* ======== upload-large-files widget styles =========
===========================================*/

/* Button */

.upload-large-files .upload-large-files__button,
.upload-large-files .upload-large-files__button:hover,
.upload-large-files .upload-large-files__button:focus,
.upload-large-files .upload-large-files__button:active {
    display: inline-block;
    background: #FFFFFF;
    border: 1px solid #0084D5;
    border-radius: 5px;
    font-size: 16px;
    color: #0084D5;
    text-align: center;
    line-height: 16px;
    padding: 8px 30px;
    cursor: pointer
}

.upload-large-files__button.upload-large-files__button--primary {
    background: #0084D5;
    color: #fff;
}

.upload-large-files__button:hover {
    opacity: 0.8;
}

/*==========================*/
/* Jquery UI modal override*/
/*==========================*/

.largeFileUploadJqueryUiModal .ui-widget-header {
    border: none;
    border-bottom: solid 1px #8F98A7;
    padding: 16px 32px;
    border-radius: 0;
    background: #fff;
    position: relative;
}

.largeFileUploadJqueryUiModal.ui-dialog .ui-dialog-title {
    font-size: 22px;
    color: #333333;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 500;
}

.largeFileUploadJqueryUiModal .ui-button.ui-widget.ui-state-default.ui-corner-all.ui-button-icon-only.ui-dialog-titlebar-close,
.largeFileUploadJqueryUiModal .ui-widget.ui-state-default.ui-corner-all.ui-button-icon-only.ui-dialog-titlebar-close.ui-state-hover {
    position: absolute;
    z-index: 1;
    top: 28px;
    right: 22px;
    background-image:  url(../../images/close.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    width: 24px;
    height: 24px;
    text-indent: -9999px;
    direction: ltr;
    cursor: pointer;
    border: none;
    box-shadow: none;
}

.largeFileUploadJqueryUiModal .ui-button.ui-widget.ui-state-default.ui-corner-all.ui-button-icon-only.ui-dialog-titlebar-close .ui-icon-closethick {
    background: none;
}

.largeFileUploadJqueryUiModal .ui-dialog-content.ui-widget-content {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #333333;
    text-align: left;
    line-height: 24px;
    padding: 22px 32px;
    margin: 0;
}

.largeFileUploadJqueryUiModal .ui-dialog-buttonpane.ui-widget-content {
    border-top: solid 1px #8F98A7;
    padding: 16px 32px;
    margin: 0;
}

.largeFileUploadJqueryUiModal .ui-button.ui-button-text-only {
    display: inline-block;
    background: #FFFFFF;
    border: 1px solid #0084D5;
    border-radius: 5px;
    font-size: 16px;
    color: #0084D5;
    text-align: center;
    line-height: 16px;
    padding: 4px 16px;
    cursor: pointer;
    margin: 0;
    float: none;
    box-shadow: none;
    margin-right: 16px;
}

.largeFileUploadJqueryUiModal .upload-large-files__button.upload-large-files__button--primary {
    background: #0084D5;
    color: #fff;
}

.largeFileUploadJqueryUiModal .upload-large-files__button:hover {
    opacity: 0.8;
}

.largeFileUploadJqueryUiModal .ui-dialog-buttonpane .ui-dialog-buttonset {
    float: none;
    text-align: left;
}
