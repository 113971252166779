/* ELEMENTS: Typography: Titles
   -------------------------------------------------------------------------- */

.session-dialog .session-dialog-title {
    /* reseting jquery-ui styles */
    float: unset;
    margin: unset;
    width: auto;
    white-space: initial;
    /* imitating .title */
    display: block;
    font-family: var(--title--font, inherit);
    font-size: var(--title--font--size, 22px);
    font-weight: var(--title--font--weight, bold);
    color: var(--title--font--color, #000000);
}

/* ELEMENTS: Buttons: Main
   -------------------------------------------------------------------------- */

.session-dialog .session-button {
    /* reseting jquery-ui styles */
    position: static;
    margin: unset;
    width: auto;
    height: auto;
    text-indent: unset;
    /* imitating .button */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; /* in case it's within a flex parent */
    outline-width: var(--buttons--outline--width, 0);
    outline-style: solid;
    outline-color: var(--buttons--outline--color, #ffffff);
    border-radius: var(--buttons--border--radius, 4px);
    border-width: var(--buttons--border--width, 1px);
    border-style: solid;
    border-color: var(--buttons--border--color, #333333);
    min-height: var(--buttons--height, 40px);
    background-color: var(--buttons--background--color, #333333);
    padding-top: calc(var(--buttons--padding--v, 8px) - var(--buttons--border--width, 1px));
    padding-right: calc(var(--buttons--padding--h, 16px) - var(--buttons--border--width, 1px));
    padding-bottom: calc(var(--buttons--padding--v, 8px) - var(--buttons--border--width, 1px));
    padding-left: calc(var(--buttons--padding--h, 16px) - var(--buttons--border--width, 1px));
    font-family: var(--buttons--font, inherit);
    font-size: var(--buttons--font--size, 16px);
    line-height: calc(var(--buttons--height, 40px) - (var(--buttons--padding--v, 8px) * 2));
    font-weight: var(--font--weight--bold, 700);
    font-style: normal;
    text-transform: initial;
    text-decoration: none;
    text-align: center;
    vertical-align: baseline;
    color: var(--buttons--font--color, #ffffff);
    cursor: pointer;
}

.session-dialog .session-button:focus {
    outline: 2px solid #979797;
    outline-offset: 2px;
}

.session-dialog .session-button:hover,
.session-dialog .session-button:active {
    text-decoration: none;
}

.session-dialog .session-button-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: var(--button__icon--width, 12px);
    height: var(--button__icon--height, 12px);
    background-color: currentColor;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
    mask-position: center;
    -webkit-mask-position: center;
}

/* ELEMENTS: Buttons: Main: Secondary
   -------------------------------------------------------------------------- */

.session-dialog-buttonset .session-button:nth-of-type(2) {
    border-color: var(--buttons--border--color, #333333);
    background-color: var(--buttons--background--color, #ffffff);
    color: var(--buttons--font--color, #333333);
}

/* ELEMENTS: Buttons: Iconographics
   -------------------------------------------------------------------------- */

.session-dialog .session-dialog-titlebar-close {
    --buttons--height: 24px;
    border-color: var(--buttons--border--color, transparent);
    min-height: var(--buttons--height, 24px);
    background-color: var(--buttons--background--color, transparent);
    padding-top: calc(var(--buttons--padding--v, 6px) - var(--buttons--border--width, 1px));
    padding-right: calc(var(--buttons--padding--h, 6px) - var(--buttons--border--width, 1px));
    padding-bottom: calc(var(--buttons--padding--v, 6px) - var(--buttons--border--width, 1px));
    padding-left: calc(var(--buttons--padding--h, 6px) - var(--buttons--border--width, 1px));
    font-size: var(--buttons--font--size, 0);
    line-height: calc(var(--buttons--height, 24px) - (var(--buttons--padding--v, 6px) * 2));
    color: var(--buttons--font--color, #333333);
}

.session-dialog .session-dialog-titlebar-close .session-icon-closethick {
    mask-image: var(
        --button__icon--background--image,
        var(
            --session--icon--closethick,
            url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcons/Basic/Close/Small Copy@1x%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-3' fill='%23000000'%3E%3Cpolygon id='Fill-1' points='10.6785 0 5.9995 4.73623219 1.3215 0 0.0005 1.26699437 5.9995 7.27112983 11.9995 1.26699437'%3E%3C/polygon%3E%3Cpolygon id='Fill-2' points='0 10.7330056 1.321 12 6 7.2646767 10.678 12 12 10.7330056 6 4.72887017'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
        )
    );
    -webkit-mask-image: var(
        --button__icon--background--image,
        var(
            --session--icon--closethick,
            url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcons/Basic/Close/Small Copy@1x%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-3' fill='%23000000'%3E%3Cpolygon id='Fill-1' points='10.6785 0 5.9995 4.73623219 1.3215 0 0.0005 1.26699437 5.9995 7.27112983 11.9995 1.26699437'%3E%3C/polygon%3E%3Cpolygon id='Fill-2' points='0 10.7330056 1.321 12 6 7.2646767 10.678 12 12 10.7330056 6 4.72887017'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
        )
    );
}

/* ELEMENTS: Overlay
   -------------------------------------------------------------------------- */

.session-widget-overlay {
    /* reseting jquery-ui styles */
    opacity: initial;
    -webkit-filter: none;
    filter: none;
    /* reapplying deleted inline jquery-ui styles */
    z-index: 100;
    /* intentional styles */
    background: var(--modal__overlay--background--color, var(--color--overlay--dark, rgba(0, 0, 0, 0.45)));
}

/* COMPONENTS: Bars: Buttons
   -------------------------------------------------------------------------- */
.session-dialog .session-dialog-buttonpane .session-dialog-buttonset {
    /* reseting jquery-ui styles */
    float: unset;
    /* intentional styles */
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--button-bar--cols--gap, 16px);
    row-gap: var(--button-bar--rows--gap, 8px);
    transition: all 250ms ease-in-out;
    width: 100%;
    clear: both;
}

.session-dialog .session-dialog-buttonpane .session-button {
    /* reseting jquery-ui styles */
    margin: unset;
    /* imitating .button-bar */
    flex-basis: var(--button-bar__buttons--width, auto);
}

/* COMPONENTS: Overays: Modals: Expired session
   -------------------------------------------------------------------------- */

.session-dialog {
    /* reapplying deleted inline jquery-ui styles */
    height: auto;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 101;
    /* intentional styles */
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 4px 1px var(--modal--shadow, var(--color--shadow, rgba(0, 0, 0, 0.2)));
    border-radius: var(--modal--border--radius, var(--boxes--border--radius, 4px));
    border-width: var(--modal--border--width, 0);
    border-style: solid;
    border-color: var(--modal--border--color, var(--boxes--border--color, #979797));
    width: calc(100% - (var(--grid--laterals, var(--spacer--m, 16px)) * 2)) !important;
    max-width: 542px;
    background-color: var(--modal--background--color, var(--boxes--background--color, #ffffff));
    padding-top: var(--modal--padding--outers--v, var(--spacer--xxl, 40px));
    padding-right: var(--modal--padding--outers--h, var(--spacer--l, 24px));
    padding-bottom: var(--modal--padding--outers--v, var(--spacer--xxl, 40px));
    padding-left: var(--modal--padding--outers--h, var(--spacer--l, 24px));
    font-family: var(--font, ''), Arial, Helvetica, sans-serif;
    font-size: var(--font--size, 16px);
    line-height: calc(2px + 2ex + 2px);
    color: var(--color--font, #333333);
}

.session-dialog .session-dialog-titlebar {
    /* intentional styles */
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: var(--modal--padding--inners--h, var(--spacer--m, 16px));
    margin-top: calc(-1 * var(--modal--padding--inners--v, var(--spacer--m, 16px)));
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--dividers--border--color, #979797);
    padding-top: var(--modal--padding--inners--v, var(--spacer--m, 16px));
    padding-right: 0;
    padding-bottom: var(--modal--padding--inners--v, var(--spacer--m, 16px));
    padding-left: 0;
}

.session-dialog .session-dialog-content {
    /* reseting jquery-ui styles */
    position: static;
    border: unset;
    background: unset;
    padding: unset;
    color: inherit;
}

.session-dialog * + .session-dialog-content {
    padding-top: var(--modal--padding--inners--v, var(--spacer--m, 16px));
}

.session-dialog .session-dialog-buttonpane {
    /* reseting jquery-ui styles */
    margin: unset;
    border: unset;
    background: unset;
    padding: unset;
    text-align: inherit;
    /* intentional styles */
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: var(--modal--padding--inners--h, var(--spacer--m, 16px));
}

.session-dialog * + .session-dialog-buttonpane {
    padding-top: var(--modal--padding--outers--v, var(--spacer--xxl, 40px));
}
