.datepicker-skin-scheduling {
    font-size: 90%;
}

.datepicker-skin-scheduling .ui-widget {
    font-family: "Lucida Grande", "Lucida Sans Unicode", Helvetica, Arial, Verdana, sans-serif;
    background: #5A5A5A;
    border: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
}

.datepicker-skin-scheduling .ui-datepicker-header {
    border: none;
    border-radius: 0px;
    background: #5A5A5A;
    font-weight: normal;
    font-size: 15px;
    height: 2em;
}

.datepicker-skin-scheduling .ui-datepicker-header .ui-state-hover {
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
}

.datepicker-skin-scheduling .ui-datepicker {
    display: none;
    padding: 0;
    width: 100%;
    box-shadow: none;
    background: transparent;
}

.datepicker-skin-scheduling .ui-datepicker table {
    width: 100%;
    font-size: .9em;
    border-collapse: collapse;
    margin: 0;
}

.datepicker-skin-scheduling .ui-datepicker th {
    text-align: center;
    background-color: #999999;
    padding: 1em 0;
    color: #ffffff;
    font-size: 13px;
    font-weight: normal;
    border: none;
}

.datepicker-skin-scheduling .ui-datepicker td {
    background: #ffffff;
    border: none;
    padding: 0;
}

.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: .2em 0;
    margin: 0;
}

.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
    height: 1.8em;
    color: #ffffff;
}

.datepicker-skin-scheduling td .ui-state-default {
    background: transparent;
    border: none;
    text-align: center;
    padding: .5em;
    margin: 0;
    font-weight: normal;
    color: #001C43;
    font-size: 16px;
}

.datepicker-skin-scheduling .ui-state-disabled  {
    opacity: 1;
}

.datepicker-skin-scheduling .ui-state-disabled .ui-state-default {
    color: #B7B7B7;
}

.datepicker-skin-scheduling td .ui-state-active,
.datepicker-skin-scheduling td .ui-state-hover {
    background: #a9a9a9;
}

.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-prev,
.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 2px;
    width: 1.8em;
    height: 1.8em;
}

.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-prev-hover,
.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-next-hover {
    top: 1px;
}

.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-prev span,
.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    background: url(ui-icons_ffffff_256x240.png) -32px 0;
    margin-top: 0;
    top: 0;
    font-weight: normal;
}

.datepicker-skin-scheduling .ui-datepicker select.ui-datepicker-month,
.datepicker-skin-scheduling .ui-datepicker select.ui-datepicker-year {
    width: 49%;
}

.datepicker-skin-scheduling .ui-datepicker-unselectable.ui-state-disabled,
.datepicker-skin-scheduling .ui-datepicker-unselectable.ui-state-disabled span,
.datepicker-skin-scheduling .ui-datepicker-other-month.ui-datepicker-unselectable {
    color: #B7B7B7;
    background: #ffffff;
}

.datepicker-skin-scheduling .ui-datepicker th span,
.datepicker-skin-scheduling .ui-datepicker th a,
.datepicker-skin-scheduling .ui-datepicker td span,
.datepicker-skin-scheduling .ui-datepicker td a {
    display: block;
    text-decoration: none;
    padding: 0.35em 0.25em;
    text-align: center;
}

.datepicker-skin-scheduling .ui-datepicker td span.ui-state-hover,
.datepicker-skin-scheduling .ui-datepicker td a.ui-state-hover,
.datepicker-skin-scheduling .ui-datepicker td.selectable-day {
    background-color: #e5e5e5;
}

.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-current-day,
.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-current-day a,
.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-current-day a.ui-state-hover {
    background-color: #BBD7FF;
    color: #001C43;
}

.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-today,
.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-today a,
.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-today a.ui-state-hover {
    font-weight: bold;
}

.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-prev-hover,
.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-next-hover,
.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-next,
.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-prev {
    top: .9em;
    border:none;
    background: transparent none;
}

.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-prev-hover {
    left: 2px;
}

.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-next-hover {
    right: 2px;
}

.datepicker-skin-scheduling .ui-datepicker .ui-datepicker-prev span {
	background-position: -96px 0;
}

.schedulerWidget {
    background: #ffffff;
    padding: 0.65em;
    margin-bottom: 15px;
    border: solid 1px #dcdcdc;
}

.schedulerWidget.error {
    background: #ffffff;
    padding: 0.65em;
    margin-bottom: 5px;
    border: solid 1px #BB0000;
}

.schedulerWidget .headerContainer .title {
    font-size: 14px;
    font-weight: bold;
}

.schedulerWidget .headerContainer .description {
    font-size: 12px;
    padding-top: 0.35em;
    padding-bottom: 0.35em;
}

.schedulerWidget .results .availability {
    font-size: 14px;
    padding: 0.7em 0.35em;
}

.schedulerWidget .results .resultsList,
.schedulerWidget .results .noResults {
    height: 24em;
    overflow: auto;
}

.schedulerWidget .form__loader {
    height: 27.31em;
}

.schedulerWidget .results .noResults .noResultsText {
    font-size: 13px;
}

.schedulerWidget .results .resultsList .resultItem {
    background: transparent;
    padding: 0.75em;
    border-bottom: solid 1px #B7B7B7;
}

.schedulerWidget .results .resultsList .resultItem:hover {
    background: #e5e5e5;
    cursor: pointer;
}

.schedulerWidget .results .resultsList .resultItem.selected {
    background: #BBD7FF;
    cursor: pointer;
}

.schedulerWidget .results .resultsList .resultItem .title {
    font-size: 14px;
    font-weight: bold;
}

.schedulerWidget .results .resultsList .resultItem .time,
.schedulerWidget .results .resultsList .resultItem .location,
.schedulerWidget .results .resultsList .resultItem .allDay{
    font-size: 13px;
}

.schedulerWidget .results .resultsList .resultItem .time .timeZone{
    color: #868686;
    padding-left: 0.4em;
}
