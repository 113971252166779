.useMyCurrentLocation {
    --useMyCurrentLocation--direction: row;
    --useMyCurrentLocation--align: center;
    --useMyCurrentLocation--gap: var(--spacer--xs, 4px);
    --useMyCurrentLocation--icon: url("data:image/svg+xml,%3Csvg width='11' height='14' viewBox='0 0 11 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3CclipPath id='ht5cf4dfaa'%3E%3Cpath d='M1456 0v1386H0V0h1456z'/%3E%3C/clipPath%3E%3CclipPath id='d3dn69baib'%3E%3Cpath d='M9.07 3.226c.392 1.241.273 2.446-.354 3.58-.824 1.491-1.668 3.002-2.485 4.464a7402.3 7402.3 0 0 0-.986 1.767c-.017.031-.033.055-.047.077l-.019.03-.543.856-.534-.863-.027-.044c-.02-.033-.042-.067-.063-.107L3.126 11.4l-.84-1.504L.592 6.852a4.566 4.566 0 0 1 .462-5.166A4.51 4.51 0 0 1 4.005.046C6.252-.27 8.397 1.078 9.07 3.226zM4.643 1.258a3.673 3.673 0 0 0-.468.032 3.274 3.274 0 0 0-2.143 1.194 3.302 3.302 0 0 0-.755 1.822c-.057.673.09 1.349.423 1.939l2.534 4.547.408.73.48-.86c.816-1.461 1.66-2.971 2.482-4.46A3.195 3.195 0 0 0 7.86 3.6a3.347 3.347 0 0 0-3.217-2.343zM4.67 2.51a2.146 2.146 0 0 1 1.49.641c.392.398.605.937.588 1.494A2.107 2.107 0 0 1 4.646 6.69l-.054.002a2.11 2.11 0 0 1-1.482-.656 2.05 2.05 0 0 1-.574-1.484 2.1 2.1 0 0 1 1.987-2.042l.147-.001zM4.64 3.766l-.02.001c-.431 0-.803.372-.816.822a.809.809 0 0 0 .228.584.842.842 0 0 0 1.45-.56.798.798 0 0 0-.23-.585.882.882 0 0 0-.612-.262z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23ht5cf4dfaa)' transform='translate(-45 -318)'%3E%3Cg clip-path='url(%23d3dn69baib)' transform='translate(45 318)'%3E%3Cpath fill='%231063BA' d='M0 0h9.296v14H0V0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    --useMyCurrentLocation--icon--width: 11px;
    --useMyCurrentLocation--icon--height: 14px;
    --useMyCurrentLocation--font: inherit;
    --useMyCurrentLocation--font--size: var(--font--size--00, 14px);
    --useMyCurrentLocation--font--weight: var(--font--weight--bold, 700);
    --useMyCurrentLocation--font--color: var(--color--clickeable, #1063BA);

    all: unset; /* general reset to avoid any inheritance */
    flex-shrink: 0; /* in case it's within a flex parent */
    display: flex;
    flex-direction: var(--useMyCurrentLocation--direction);
    align-items: var(--useMyCurrentLocation--align);
    gap: var(--useMyCurrentLocation--gap);
    box-sizing: border-box;
    color: var(--useMyCurrentLocation--font--color);
    cursor: pointer;
}

.useMyCurrentLocation:hover {
    --useMyCurrentLocation--font--color: var(--color--clickeable, #1063BA);
}

.useMyCurrentLocation:focus {
    outline-width: 1px;
    outline-style: dotted;
    outline-color: var(--useMyCurrentLocation--font--color);
}

.useMyCurrentLocation__icon {
    flex-shrink: 0;
    width: var(--useMyCurrentLocation--icon--width);
    height: var(--useMyCurrentLocation--icon--height);
    background-color: currentColor;
    mask-image: var(--useMyCurrentLocation--icon);
    -webkit-mask-image: var(--useMyCurrentLocation--icon);
    mask-position: center center;
    -webkit-mask-position: center center;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
    transition: all 0.3s ease;
}

.useMyCurrentLocation__text {
    margin-bottom: -1px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    color: currentColor;
    font-family: var(--useMyCurrentLocation--font);
    font-size: var(--useMyCurrentLocation--font--size);
    line-height: normal;
    font-weight: var(--useMyCurrentLocation--font--weight);
    text-decoration: none;
    text-transform: none;
    transition: all 0.3s ease;
}

.useMyCurrentLocation:hover .useMyCurrentLocation__text {
    border-bottom-color: currentcolor;
}
