/* Safe button styles*/

.mce-container button {
    background: 0;
    background-attachment: scroll;
    background-clip: border-box;
    background-color: transparent;
    background-image: none;
    background-origin: padding-box;
    background-position: 0 0;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: repeat;
    background-size: auto auto;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: inline-block;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    transition-duration: 0.3s;
    transition-property: color, background-color, border-color;
    max-width: 	none;
    max-height: none;
    min-width:0;
    min-height:0;
}

.mce-container  button:active,
.mce-container  button:hover {
    background: 0;
    background-attachment: scroll;
    background-clip: border-box;
    background-color: transparent;
    background-image: none;
    background-origin: padding-box;
    background-position: 0 0;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: repeat;
    background-size: auto auto;
    color: #595959;
    box-shadow: none;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 4px 6px;
    font-size: 14px;
    line-height: 20px;
    *line-height: 16px;
    cursor: pointer;
    text-align: center;
    overflow: visible;
    -moz-appearance: none;
    -webkit-appearance: none;
    display: inline-block;
    border: 0;
    text-shadow: 1px 1px none;
    filter: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.mce-container .mce-window-head button.mce-close:hover{
    padding:0
}

.mce-primary button:hover {
    color: #fff;
}
/*---End Safe button styles---*/

.mce-menubar .mce-menubtn.mce-active {
    border-bottom: none;
    z-index: 65534
}

div.mce-menubtn.mce-opened {
    border-bottom-color: white;
    z-index: 65534
}
