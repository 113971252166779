.RecordAgain {
    display: none
}

.VerifyRecord .RecordAgain {
    position: absolute;
    display: flex;
    height: 100%;
    justify-content: center;
    background-color: var(--video--color--background);
    backdrop-filter: blur(var(--video--spacer--50));
}

.ControlContainer {
    display: flex;
    flex-direction: column;
    gap: var(--video--spacer--16);
    align-items: center;
    justify-content: var(--video--justify--content);
}

.VerifyRecAgainBtns {
    display: flex;
    flex-direction: column;
    margin-top: var(--video--spacer--4);
    gap: var(--video--spacer--16);
}

.ControlsItem.VerifyRecAgainText {
    color: var(--video--color--white);
    font-family: var(--video--font--family);
    font-size: var(--video--body--text);
    width: var(--video--spacer--250);
    text-align: center;
}

.ControlsItem.NoRecAgainBtn,
.ControlsItem.ConfirmRecAgain {
    min-width: var(--video--spacer--250);
}

@media (width <= 425px) {
    .ControlsItem.VerifyRecAgainText {
        width: 180px;
        font-weight: var(--video--font--weight--bold);
    }

    .RecordAgain .VerifyRecAgainBtns {
        display: none;
    }

    .VerifyRecord .RecordAgain {
        height: auto;
        aspect-ratio: var(--video--aspect--ratio);
    }
}