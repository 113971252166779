/* Modal styles*/
.modal-large-files {
    display: block;
    /* Probably need media queries here */
    width: 590px;
    max-width: 100%;
    height: 485px;
    max-height: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: #ffffff;
    border: 1px solid #8F98A7;
    border-radius: 5px;
}

.modal-large-files-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background: rgba(0, 0, 0, 0.5);
}

.ui-widget-overlay {
    /* reseting jquery-ui styles */
    opacity: initial;
    -webkit-filter: none;
    filter: none;
    /* intentional styles */
    background: var(--modal__overlay--background--color, var(--color--overlay--dark, rgba(0, 0, 0, 0.45)));
}

.modal-large-files-guts {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.modal-large-files * {
    box-sizing: border-box;
}

.modal-large-files .close-button {
    position: absolute;
    z-index: 1;
    top: 12px;
    right: 32px;
    background-image:  url(../../images/close.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    width: 24px;
    height: 24px;
    text-indent: -9999px;
    direction: ltr;
    cursor: pointer;
}

.ui-dialog .ui-dialog-titlebar-close {
    --buttons--height: 24px;
    border-color: var(--buttons--border--color, transparent);
    min-height: var(--buttons--height, 24px);
    background-color: var(--buttons--background--color, transparent);
    font-size: var(--buttons--font--size, 0);
    color: var(--buttons--font--color, #333333);
}

.ui-dialog .ui-dialog-titlebar-close .ui-icon-closethick {
    mask-image: var(--button__icon--background--image, var(--session--icon--closethick, url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcons/Basic/Close/Small Copy@1x%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-3' fill='%23000000'%3E%3Cpolygon id='Fill-1' points='10.6785 0 5.9995 4.73623219 1.3215 0 0.0005 1.26699437 5.9995 7.27112983 11.9995 1.26699437'%3E%3C/polygon%3E%3Cpolygon id='Fill-2' points='0 10.7330056 1.321 12 6 7.2646767 10.678 12 12 10.7330056 6 4.72887017'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E")));
    -webkit-mask-image: var(--button__icon--background--image, var(--session--icon--closethick, url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcons/Basic/Close/Small Copy@1x%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-3' fill='%23000000'%3E%3Cpolygon id='Fill-1' points='10.6785 0 5.9995 4.73623219 1.3215 0 0.0005 1.26699437 5.9995 7.27112983 11.9995 1.26699437'%3E%3C/polygon%3E%3Cpolygon id='Fill-2' points='0 10.7330056 1.321 12 6 7.2646767 10.678 12 12 10.7330056 6 4.72887017'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E")));
}

/* ======== upload-large-files widget styles =========
===========================================*/

/*Reset*/
/*To prevent other css overrides from the template*/
.upload-large-files * {
    animation : none;
    animation-delay : 0;
    animation-direction : normal;
    animation-duration : 0;
    animation-fill-mode : none;
    animation-iteration-count : 1;
    animation-name : none;
    animation-play-state : running;
    animation-timing-function : ease;
    backface-visibility : visible;
    background : 0;
    background-attachment : scroll;
    background-clip : border-box;
    background-color : transparent;
    background-image : none;
    background-origin : padding-box;
    background-position : 0 0;
    background-position-x : 0;
    background-position-y : 0;
    background-repeat : repeat;
    background-size : auto auto;
    border : 0;
    border-style : none;
    border-width : medium;
    border-color : inherit;
    border-bottom : 0;
    border-bottom-color : inherit;
    border-bottom-style : none;
    border-bottom-width : medium;
    border-collapse : separate;
    border-image : none;
    border-left : 0;
    border-left-color : inherit;
    border-left-style : none;
    border-left-width : medium;
    border-radius : 0;
    border-right : 0;
    border-right-color : inherit;
    border-right-style : none;
    border-right-width : medium;
    border-spacing : 0;
    border-top : 0;
    border-top-color : inherit;
    border-top-left-radius : 0;
    border-top-right-radius : 0;
    border-top-style : none;
    border-top-width : medium;
    bottom : auto;
    box-shadow : none;
    box-sizing : content-box;
    caption-side : top;
    clear : none;
    clip : auto;
    color : inherit;
    columns : auto;
    column-count : auto;
    column-fill : balance;
    column-gap : normal;
    column-rule : medium none currentColor;
    column-rule-color : currentColor;
    column-rule-style : none;
    column-rule-width : none;
    column-span : 1;
    column-width : auto;
    content : normal;
    counter-increment : none;
    counter-reset : none;
    cursor : auto;
    direction : ltr;
    empty-cells : show;
    float : none;
    font : normal;
    font-family : inherit;
    font-size : medium;
    font-style : normal;
    font-variant : normal;
    font-weight : normal;
    height : auto;
    hyphens : none;
    left : auto;
    letter-spacing : normal;
    line-height : normal;
    list-style : none;
    list-style-image : none;
    list-style-position : outside;
    list-style-type : disc;
    margin : 0;
    margin-bottom : 0;
    margin-left : 0;
    margin-right : 0;
    margin-top : 0;
    max-height : none;
    max-width : none;
    min-height : 0;
    min-width : 0;
    opacity : 1;
    orphans : 0;
    outline : 0;
    outline-color : invert;
    outline-style : none;
    outline-width : medium;
    overflow : visible;
    overflow-x : visible;
    overflow-y : visible;
    padding : 0;
    padding-bottom : 0;
    padding-left : 0;
    padding-right : 0;
    padding-top : 0;
    page-break-after : auto;
    page-break-before : auto;
    page-break-inside : auto;
    perspective : none;
    perspective-origin : 50% 50%;
    position : static;
    right : auto;
    tab-size : 8;
    table-layout : auto;
    text-align : inherit;
    text-align-last : auto;
    text-decoration : none;
    text-decoration-color : inherit;
    text-decoration-line : none;
    text-decoration-style : solid;
    text-indent : 0;
    text-shadow : none;
    text-transform : none;
    top : auto;
    transform : none;
    transform-style : flat;
    transition : none;
    transition-delay : 0s;
    transition-duration : 0s;
    transition-property : none;
    transition-timing-function : ease;
    unicode-bidi : normal;
    vertical-align : baseline;
    visibility : visible;
    white-space : normal;
    widows : 0;
    width : auto;
    word-spacing : normal;
    z-index : auto;
}

/* Body */
.upload-large-files__body {
    border-top: solid 1px #8F98A7;
    border-bottom: solid 1px #8F98A7;
    padding: 16px 32px;
    height: 330px;
    overflow-y: auto;
}

/* Header */
.upload-large-files__header {
    padding: 16px 32px;
}

.upload-large-files__headerTitle {
    font-size: 22px;
    line-height: 20px;
    font-weight: 500;
}

.upload-large-files__headerText {
    font-size: 15px;
    line-height: 18px;
}

.upload-large-files__bodyInfoArea {
    margin-bottom: 16px
}

/*List*/
.upload-large-files__particularProgressList {
    list-style: none;
    padding: 0 16px;
    margin: 0;
}

/*_List Item */
.upload-large-files__item {
    list-style-type : none;
    border-bottom: solid 1px #dddddd;
    padding-bottom: 12px;
    margin-bottom: 12px;
}

.upload-large-files__particularProgressList .upload-large-files__item:last-child {
    border-bottom: none
}

.upload-large-files__itemTitle {
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    color:#333;
}

.upload-large-files .upload-large-files__itemDetail,
.upload-large-files .upload-large-files__itemDetail > * {
    font-size: 14px;
    color: #999999;
}

.upload-large-files__itemHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.upload-large-files__itemWarning {
    color: #FD7574;
    font-size: 14px;
    font-style: italic;
}

/*Progress element*/

/*Progress Bar*/
/* All HTML5 progress enabled browsers */
.upload-large-files__itemProgressBar {
    width: 100%;
    margin: 6px 0;
    /* Turns off styling - not usually needed, but good to know. */
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    /* gets rid of default border in Firefox and Opera. */
    border: none;
    /* Needs to be in here for Safari polyfill so background images work as expected. */
    background-size: auto;
    /* Dimensions */
    height: 5px;
    /* Firefox  */
    background: #DDDDDD;
    /* IE10 */
    color: #0084D5;
}

/* Chrome */
.upload-large-files__itemProgressBar::-webkit-progress-bar {
    background: #DDDDDD;
}

/* Chrome */
.upload-large-files__itemProgressBar::-webkit-progress-value {
    background: #0084D5;
}

/* Firefox */
.upload-large-files__itemProgressBar::-moz-progress-bar {
    background: #0084D5;
}

/* Action bar */

.upload-large-files__actionBar {
    padding: 16px 32px;
}

/*Errors*/

/* IE10 */
.upload-large-files__error .upload-large-files__itemProgressBar {
    color: #FD7574;
}

/* Firefox */
.upload-large-files__error .upload-large-files__itemProgressBar::-moz-progress-bar {
    background: #FD7574;
}

/* Chrome */
.upload-large-files__error .upload-large-files__itemProgressBar::-webkit-progress-value {
    background: #FD7574;
}

.upload-large-files__formError {
    color: red;
}

.largeFileDialogClosed {
    display: none;
}

/* COMPONENTS: Modal
   -------------------------------------------------------------------------- */

.ui-dialog {
    /* reseting jquery-ui styles */
    border: unset !important;
    /* intentional styles */
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 4px 1px var(--modal--shadow, var(--color--shadow, rgba(0, 0, 0, 0.2)));
    width: calc(100% - (var(--grid--laterals, var(--spacer--m, 16px)) * 2)) !important;
    max-width: 542px;
    background-color: var(--modal--background--color, var(--boxes--background--color, #FFFFFF));
    padding-top: var(--modal--padding--outers--v, var(--spacer--xxl, 40px));
    padding-right: var(--modal--padding--outers--h, var(--spacer--l, 24px));
    padding-bottom: var(--modal--padding--outers--v, var(--spacer--xxl, 40px));
    padding-left: var(--modal--padding--outers--h, var(--spacer--l, 24px));
    font-family: var(--font, ''), Arial, Helvetica, sans-serif;
    font-size: var(--font--size, 16px);
    line-height: calc(2px + 2ex + 2px);
    color: var(--color--font, #333333);
}

.ui-dialog *:before,
.ui-dialog *:after {
    /* reseting jquery-ui styles */
    display: none;
}

.ui-dialog .ui-resizable-handle {
    /* reseting jquery-ui styles */
    display: none !important;
}

.ui-dialog .ui-dialog-titlebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: var(--modal--padding--inners--h, var(--spacer--m, 16px));
    margin-top: calc(-1 * var(--modal--padding--inners--v, var(--spacer--m, 16px)));
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--dividers--border--color, #979797);
    padding-right: 0;
    padding-left: 0;
}

.ui-dialog .ui-dialog-buttonpane {
    /* reseting jquery-ui styles */
    border: unset;
    background: unset;
    text-align: inherit;
    color: inherit;
    /* intentional styles */
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: var(--modal--padding--inners--h, var(--spacer--m, 16px));
}

/* ELEMENTS: Buttons
   -------------------------------------------------------------------------- */

.ui-dialog .ui-button {
    /* reseting jquery-ui styles */
    position: static;
    margin: unset;
    width: auto;
    height: auto;
    text-indent: unset;
    /* imitating .button */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; /* in case it's within a flex parent */
    outline-width: var(--buttons--outline--width, 0);
    outline-style: solid;
    outline-color: var(--buttons--outline--color, #FFFFFF);
    min-height: var(--buttons--height, 40px);
    padding-top: calc(var(--buttons--padding--v, 8px) - var(--buttons--border--width, 1px));
    padding-right: calc(var(--buttons--padding--h, 16px) - var(--buttons--border--width, 1px));
    padding-bottom: calc(var(--buttons--padding--v, 8px) - var(--buttons--border--width, 1px));
    padding-left: calc(var(--buttons--padding--h, 16px) - var(--buttons--border--width, 1px));
    font-family: var(--buttons--font, inherit);
    line-height: calc(var(--buttons--height, 40px) - (var(--buttons--padding--v, 8px) * 2));
    font-weight: var(--font--weight--bold, 700);
    font-style: normal;
    text-transform: initial;
    text-decoration: none;
    text-align: center;
    vertical-align: baseline;
    cursor: pointer;
}

.ui-dialog .ui-button:hover,
.ui-dialog .ui-button:focus,
.ui-dialog .ui-button:active {
    text-decoration: none;
}

.ui-dialog .ui-button-icon {
    /* reseting jquery-ui styles */
    position: static;
    margin: unset;
    background: unset;
    /* imitating .button */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: var(--button__icon--width, 12px);
    height: var(--button__icon--height, 12px);
    background-color: currentColor;
}

/* ELEMENTS: Buttons: Secondary
   -------------------------------------------------------------------------- */

.ui-dialog-buttonset .ui-button:nth-of-type(2) {
    border-color: var(--buttons--border--color, #333333);
    background-color: var(--buttons--background--color, #FFFFFF);
    color: var(--buttons--font--color, #333333);
}

/* COMPONENTS: Button bar
   -------------------------------------------------------------------------- */
.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
    /* reseting jquery-ui styles */
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--button-bar--cols--gap, 16px);
    row-gap: var(--button-bar--rows--gap, 8px);
    float: unset;
    transition: all 250ms ease-in-out;
    clear: both;
    /* intentional styles */
    width: 100%;
}

.ui-dialog .ui-dialog-buttonpane .ui-button {
    /* reseting jquery-ui styles */
    margin: unset;
    /* imitating .button-bar */
    flex-basis: var(--button-bar__buttons--width, auto);
}
