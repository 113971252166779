.ui-autocomplete-input.live-search--open {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-color: var(--form__item__field--border--color) !important;
    box-shadow: none !important;
}

.ui-widget.ui-widget-content.live-search {
    display: block;
    position: absolute;
    left: -100%;
    z-index: 1051;
    list-style: none;
    margin: 0;
    border-radius: var(--form__item__field--border--radius, .4rem);
    border-top-left-radius: 0;
    border: 1px solid var(--form__item__field--border--color, #E8E8E8);
    padding: 0;
    background-color: #FFFFFF;
    width: auto;
    color: var(--form__item__field--font--color, #666666);
    overflow: hidden;
    -moz-transform: translateY(-1px);
    -webkit-transform: translateY(-1px);
    -o-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    transform: translateY(-1px);
}

.ui-widget.ui-widget-content.live-search .live-search__item {
    padding: 6px;
    color: inherit;
    cursor: pointer;
}

.ui-widget.ui-widget-content.live-search .live-search__item_active,
.ui-widget.ui-widget-content.live-search .live-search__item:hover {
    background-color: var(--color--clickeable, #227FFF);
    color: #FFFFFF;
}

.ui-widget.ui-widget-content.live-search .live-search__item__title {
    margin: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
    font-weight: inherit;
    color: inherit;
}

.ui-widget.ui-widget-content.live-search .live-search__item__title a {
    color: inherit;
    display: block;
}

.ui-widget.ui-widget-content.live-search .live-search__item__title__match {
    font-weight: 900;
    text-decoration: underline;
}

.ui-widget.ui-widget-content.live-search .live-search__item__details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: var(--spacer--xs, .4rem) 0 0 0;
    padding: 0;
    font-size: 87.5%;
    border: 0;
    background-color: transparent;
    color: inherit;
    font-weight: inherit;
}

.ui-widget.ui-widget-content.live-search .live-search__item__details span + span:before {
    content: '\00a0·\00a0';
}

.semantic-suggestions-header {
    pointer-events: none;
    font-style: italic;
}
